import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "@components/Button"

const Content = ({ children }) => {
  return (
    <div className="flex items-center justify-center">
      <div data-aos="fade-left" className="ml-auto md:w-5/6 lg:ml-0 lg:w-4/6">
        <h3 className="text-clamp-35-62 mt-15 md:mt-0">About JK.</h3>
        <p className="mt-clamp-9-16">{children}</p>
        <div className="flex">
          <Button link="/about-us" className="ml-auto text-gray-darkest mt-clamp-11-17">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  )
}

const Image = ({ image }) => {
  return (
    <div data-aos="fade-right" className="flex">
      <div className="md:ml-auto md:w-11/12">
        <GatsbyImage objectPosition="top" objectFit="cover" image={getImage(image)} alt="About JK" />
      </div>
    </div>
  )
}

const Default = () => {
  const data = useStaticQuery(query)
  const { aboutJkImage } = data
  return (
    <>
      <div data-aos="fade-right" className="flex">
        <div className="md:ml-auto md:w-11/12">
          <GatsbyImage objectPosition="top" objectFit="cover" image={getImage(aboutJkImage)} alt="About JK" />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div data-aos="fade-left" className="ml-auto md:w-5/6 lg:ml-0 lg:w-4/6">
          <h3 className="text-clamp-35-62 mt-15 md:mt-0">About JK.</h3>
          <p className="mt-clamp-9-16">
            We’ve spent 35 years sharpening the skills that move your business forward. We’re your partner and your
            guide, ready to help you to find your voice, inspire your salespeople, and connect with the customers who
            matter most.
          </p>
          <div className="flex">
            <Button link="/about-us" className="ml-auto text-gray-darkest mt-clamp-11-17">
              Learn more
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export const AboutJk = ({ children }) => {
  return (
    <div className="container">
      <div className="grid-cols-2 md:grid">{children}</div>
    </div>
  )
}

AboutJk.Default = Default
AboutJk.Content = Content
AboutJk.Image = Image

const query = graphql`
  query {
    aboutJkImage: file(relativePath: { eq: "global/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
