import { LogoClient } from "@components/UI"
import React from "react"

export const ClientList = ({ className = "gap-x-6 lg:gap-x-12", clients = [], divs = true }) => {
  return (
    <div
      data-aos="stagger"
      className={`client-list grid grid-cols-2 sm:flex flex-wrap justify-between items-center ${className}`}
    >
      {clients.map((client, index) => {
        return (
          <span key={index} className={`${client}-icon flex`}>
            <LogoClient slug={client} />
          </span>
        )
      })}
      {divs ? (
        <>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </>
      ) : null}
    </div>
  )
}
