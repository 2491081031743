import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "@components/Button"
import ShapeOne from "@svg/about-jk/shape-01.svg"

const Content = ({ children }) => {
  return (
    <div className="flex items-center justify-center">
      <div data-aos="fade-right" className="md:w-5/6 lg:w-4/6">
        <h3 className="text-clamp-35-62 mt-15 md:mt-0">About JK.</h3>
        <p className="mt-clamp-9-16">{children}</p>
        <div className="flex">
          <div className="w-1/2 ml-auto">
            <Button link="/about-us" className="text-gray-darkest mt-clamp-11-17">
              Learn more
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const Image = ({ image }) => {
  return (
    <div data-aos="fade-left" className="relative flex pb-16">
      <div className="absolute inset-0 flex">
        <div className="w-10/12 mt-auto ml-auto md:w-4/6">
          <div className="aspect-w-468 aspect-h-385 bg-teal extend after:bg-teal md:extend-none"></div>
        </div>
      </div>
      <div className="flex md:mr-auto md:w-10/12">
        <GatsbyImage objectPosition="top" objectFit="cover" image={getImage(image)} alt="About JK" />
      </div>
    </div>
  )
}

const Shape = ({ shape }) => {
  if (shape !== "one") {
    return <div className="aspect-w-468 aspect-h-385 bg-teal extend after:bg-teal md:extend-none"></div>
  }
  return <ShapeOne />
}

const Default = ({ image, shape }) => {
  const data = useStaticQuery(query)
  const { aboutJkImage } = data

  return (
    <>
      <div className="flex items-center justify-center">
        <div data-aos="fade-right" className="md:w-5/6 lg:w-4/6">
          <h3 className="text-clamp-35-62 mt-15 md:mt-0">About JK.</h3>
          <p className="mt-clamp-9-16">
            JK helps companies of all kinds drive more meaningful engagement with employees and candidates through
            distinctive employer brands and inspired talent communications. We blend empathy, insight, and a clear
            understanding of your goals to deliver programs and platforms that pull your people in and build the bonds
            that can grow your business.
          </p>
          <div className="flex">
            <div className="w-1/2 ml-auto">
              <Button link="/about-us" className="text-gray-darkest mt-clamp-11-17">
                Learn more
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-left" className="relative flex pb-16">
        <div className="absolute inset-0 flex">
          <div className="w-10/12 mt-auto ml-auto md:w-4/6">
            <Shape shape={shape} />
          </div>
        </div>
        <div className="flex md:mr-auto md:w-10/12">
          <GatsbyImage
            objectPosition="top"
            objectFit="cover"
            image={getImage(image ? image : aboutJkImage)}
            alt="About JK"
          />
        </div>
      </div>
    </>
  )
}

export const ImageV2 = ({ image, shape }) => {
  const data = useStaticQuery(query)
  const { aboutJkImage } = data

  return (
    <div data-aos="fade-left" className="relative flex pb-16">
      <div className="absolute inset-0 flex">
        <div className="w-10/12 mt-auto ml-auto md:w-4/6">
          <Shape shape={shape} />
        </div>
      </div>
      <div className="flex md:mr-auto md:w-10/12">
        <GatsbyImage
          objectPosition="top"
          objectFit="cover"
          image={getImage(image ? image : aboutJkImage)}
          alt="About JK"
        />
      </div>
    </div>
  )
}

export const AltAboutJk = ({ children }) => {
  return (
    <div className="container">
      <div className="flex flex-col-reverse grid-cols-2 md:grid">{children}</div>
    </div>
  )
}

AltAboutJk.Default = Default
AltAboutJk.Content = Content
AltAboutJk.Image = Image
AltAboutJk.ImageV2 = ImageV2

const query = graphql`
  query {
    aboutJkImage: file(relativePath: { eq: "global/alt-about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
