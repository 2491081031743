import { Button, ButtonAction, PillButton } from "@components/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { AltAboutJk } from "@components/AboutJk"
import { Carousel } from "@components/Carousel"
import { ClientList } from "@components/ClientList"
import { CursorMarker } from "@components/CursorMarker"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import BrandingIcon from "@svg/sustainability/branding.svg"
import CommunicationIcon from "@svg/sustainability/communication.svg"
import Illustration from "@svg/sustainability/illustration.svg"
import ReportingIcon from "@svg/sustainability/reporting.svg"
import React from "react"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import { StaticPost } from "../components/Blog/StaticPost"

const SustainabilityPage = ({ data }) => {
  const {
    aboutJkImage,
    heroImage,

    csMarcosImage,
    csSantanderImage,
    csMerckImage,
    posts,
  } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-gray-darkest",
    navigationColor: "text-white",
    displayGeneralForm: false,
  })

  const isBrowser = typeof window !== "undefined"

  const contactSubmission = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "sustainability" })
  }

  const handlEbookDownload = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "sustainability_ebook_click", { event_category: "click", event_label: "sustainability_ebook" })

    window.open("/documents/jk-sustainability-ebook.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="Sustainability" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <h1
                    className="mt-12 text-clamp-30-35"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white":
                          "Getting your sustainability story <span>right is more important than ever.</span>",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="pl-6 font-bold text-white border-l-2 border-white text-clamp-16-18">
                  <span className="text-teal">60% of consumers</span> place a high priority on sustainability in their
                  buying decisions.<sup>1</sup>
                </p>
                <p className="text-white text-clamp-16-18">
                  We’re ready to help you create and tell a powerful sustainability story that brings your data to
                  life—and makes emotional connections with your customers, employees, and shareholders.
                </p>
                <div>
                  <Button className="mt-8 text-purple-light" link="#sustainability-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative flex items-center col-span-7 lg:transform lg:translate-y-16 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="pb-6 sm:pl-5 lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative " data-aos="fade-left">
                        <GatsbyImage objectFit="contain" image={getImage(heroImage)} alt="Sustainability" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="mt-8">
          <section className="mt-16 lg:mt-32 pb-clamp-16-18">
            <div className="container">
              <div data-aos="fade">
                <PillButton staticButton={true} className="bg-purple-lighter">
                  Our services
                </PillButton>
              </div>
              <div className="mt-6 lg:w-8/12">
                <p>
                  Communicate your sustainability, ESG, and CSR commitments and initiatives in ways that elevate your
                  brand and resonate with your audiences.
                </p>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col w-full grid-cols-3 gap-8 lg:pl-6 sm:w-10/12 lg:gap-4 lg:w-full lg:grid mt-clamp-9-12">
                  <div className="flex flex-col gap-7 sm:flex-row lg:max-w-100" data-aos="fade-up">
                    <div>
                      <BrandingIcon />
                    </div>
                    <div>
                      <h3 className="font-bold text-20">Sustainability storytelling.</h3>
                      <p className="mt-4 text-16">
                        Build a distinctive, cohesive narrative and identity for your sustainability initiatives that
                        works in harmony with your corporate brand.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-7 sm:flex-row lg:max-w-100" data-aos="fade-up" data-aos-delay={100}>
                    <div>
                      <ReportingIcon />
                    </div>
                    <div>
                      <h3 className="font-bold text-20">Sustainability reporting. </h3>
                      <p className="mt-4 text-16">
                        Reimagine your sustainability, ESG, and CSR reports as dynamic, engaging, visually driven
                        storytelling experiences.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-7 sm:flex-row lg:max-w-120" data-aos="fade-up" data-aos-delay={200}>
                    <div>
                      <CommunicationIcon />
                    </div>
                    <div>
                      <h3 className="font-bold text-20">Sustainability communications strategy and assets.</h3>
                      <p className="mt-4 text-16">
                        Create a strategy and tactics to share your sustainability story inside and outside your
                        business—conveying it in persuasive ways across your communications channels and touchpoints.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-10 lg:pb-16 md:pt-clamp-14-30 lg:pt-28 bg-teal-medium">
            <div className="container">
              <div className="grid-cols-12 gap-4 lg:grid">
                <div className="flex items-center col-span-5 col-start-3" data-aos="fade-right">
                  <div>
                    <h3 className="leading-normal text-clamp-20-32 lg:pr-12">
                      Let’s talk about creating and communicating your sustainability story.
                    </h3>
                    <div>
                      <Button className="mt-8 text-gray-darkest button-down" down={true} link="#sustainability-form">
                        Let’s talk!
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="relative flex justify-center col-span-4 mt-10 lg:mt-0">
                  <div
                    className="relative flex justify-center w-full sm:w-10/12 lg:block lg:w-160 z-1"
                    data-aos="fade-left"
                  >
                    <Illustration />
                  </div>
                  <div className="absolute inset-x-0 bottom-0 lg:hidden">
                    <div className="aspect-w-667 aspect-h-93 extend before:bg-white-dark after:bg-white-dark"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-clamp-10-30 pb-clamp-16-30">
            <div className="container">
              <div>
                <div data-aos="fade">
                  <PillButton staticButton={true} className="bg-purple-lighter">
                    Our Clients
                  </PillButton>
                </div>
                <h2
                  className="mt-10 text-clamp-35-45"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-gray-darkest": "Sustainability stories we’ve created and told.",
                    }),
                  }}
                />
                <ClientList
                  className="gap-x-6 lg:gap-x-20 mt-clamp-9-8"
                  clients={[
                    "caterpillar",
                    "children",
                    "j-and-j",
                    "kenvue",
                    "marcos",
                    "merck",
                    "netflix",
                    "stryker",
                    "santander",
                    "tiffany",
                  ]}
                />
              </div>
            </div>
          </section>

          <section className="bg-gray pt-clamp-12-14 pb-clamp-15-25">
            <div className="container">
              <div>
                <PillButton className="bg-teal-medium" staticButton={true}>
                  CASE STUDY SNAPSHOTS
                </PillButton>
                <div className="mt-12">
                  <h2
                    className="leading-none text-clamp-35-45"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": "See our sustainability storytelling in action.",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="hidden grid-cols-3 gap-4 lg:grid mt-clamp-11-20">
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csMarcosImage)} alt="MARCO’S PIZZA" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">MARCO’S PIZZA</h3>
                    <h4 className="font-bold text-clamp-20-26 text-white-dark">
                      Satisfying Marco’s hunger for an irresistible CSR brand.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      Marco’s Pizza had all the ingredients for an inspiring corporate social responsibility story—but
                      needed to blend it all together and roll it out. JK created a CSR identity that telegraphs their
                      beliefs, and a toolkit that empowers everyone to share their CSR story consistently.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csSantanderImage)} alt="SANTANDER BANK, NA" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">SANTANDER BANK, NA</h3>
                    <h4 className="font-bold text-clamp-20-26 text-white-dark">
                      Telling a powerful story of ‘Prosperity with Purpose.’
                    </h4>
                    <p className="mt-4 text-white-dark">
                      We helped this leading financial services institution tell the story of their ESG strategy,
                      initiatives, and progress with a consistent theme and elevated design—and then we did it again.
                    </p>
                  </div>
                </div>
                <div>
                  <GatsbyImage objectFit="contain" image={getImage(csMerckImage)} alt="MERCK" />
                  <div className="mt-6 pr-18">
                    <h3 className="font-semi text-clamp-18-22 text-teal-light">MERCK</h3>
                    <h4 className="font-bold text-clamp-20-26 text-white-dark">
                      Making a robust report engaging and easily digestible.
                    </h4>
                    <p className="mt-4 text-white-dark">
                      We created a suite of engaging summary assets to help this global biopharma leader share the
                      highlights of their massive ESG report—and make it more meaningful for their shareholders and
                      employees.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container lg:hidden mt-clamp-11-20">
              <div className="relative flex">
                <CursorMarker>
                  <CursorMarker.Gallery />
                </CursorMarker>
                <div className="w-full sm:w-5/6 lg:w-1/2">
                  <Carousel>
                    <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csMarcosImage)} alt="MARCO’S PIZZA" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">MARCO’S PIZZA</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Satisfying Marco’s hunger for an irresistible CSR brand.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              Marco’s Pizza had all the ingredients for an inspiring corporate social responsibility
                              story—but needed to blend it all together and roll it out. JK created a CSR identity that
                              telegraphs their beliefs, and a toolkit that empowers everyone to share their CSR story
                              consistently.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csSantanderImage)} alt="SANTANDER BANK, NA" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">SANTANDER BANK, NA</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Telling a powerful story of ‘Prosperity with Purpose.’
                            </h4>
                            <p className="mt-4 text-white-dark">
                              We helped this leading financial services institution tell the story of their ESG
                              strategy, initiatives, and progress with a consistent theme and elevated design—and then
                              we did it again.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                          <GatsbyImage image={getImage(csMerckImage)} alt="MERCK" />
                          <div className="mt-6 pr-18">
                            <h3 className="font-semi text-clamp-18-22 text-teal-light">MERCK</h3>
                            <h4 className="font-bold text-clamp-18-28 text-white-dark">
                              Making a robust report engaging and easily digestible.
                            </h4>
                            <p className="mt-4 text-white-dark">
                              We created a suite of engaging summary assets to help this global biopharma leader share
                              the highlights of their massive ESG report—and make it more meaningful for their
                              shareholders and employees.
                            </p>
                          </div>
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK blends empathy, insight, and a clear understanding of your goals to deliver communications platforms
                and programs that build the bonds that grow your business.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="sustainability-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-8">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s build a  stronger ",
                          break: "",
                          "text-gray-darkest": "social good story, together.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Sustainability"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
      <section className="pt-clamp-10-30">
        <div className="container">
          <div>
            <div data-aos="fade">
              <PillButton staticButton={true} className="bg-purple-lighter">
                LEARN MORE
              </PillButton>
            </div>
            <h2
              className="mt-10 text-clamp-35-45"
              data-aos="stagger"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-gray-darkest": "Explore ideas and insights about how to tell your best sustainability story.",
                }),
              }}
            />
          </div>
          <div className="relative grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-15">
            {posts?.edges?.[1] ? (
              <StaticPost post={posts.edges[1].node}>
                <Button external={true} link={`/ideas-and-insights/${posts.edges[1].node.slug}`} className="text-white">
                  Learn more
                </Button>
              </StaticPost>
            ) : null}

            {posts?.edges?.[2] ? (
              <StaticPost post={posts.edges[2].node}>
                <Button external={true} link={`/ideas-and-insights/${posts.edges[2].node.slug}`} className="text-white">
                  Learn more
                </Button>
              </StaticPost>
            ) : null}

            {posts?.edges?.[0] ? (
              <StaticPost post={posts.edges[0].node}>
                <ButtonAction func={handlEbookDownload} className="text-white">
                  Download our ebook
                </ButtonAction>
              </StaticPost>
            ) : null}
          </div>
        </div>
        <div className="container flex pt-clamp-18-35">
          <div>
            <p className="font-semi text-14">Source:</p>
            <p className="text-14">
              1 BusinessWire, “Recent Study Reveals More Than a Third of Global Consumers Are Willing to Pay More for
              Sustainability as Demand Grows for Environmentally Friendly Alternatives,” 2021.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SustainabilityPage

export const SustainabilityPageQuery = graphql`
  query sustainabilityPageQuery {
    heroImage: file(relativePath: { eq: "sustainability/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    goingBeyondImage: file(relativePath: { eq: "social-story/going-beyond.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csMarcosImage: file(relativePath: { eq: "sustainability/marcos.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csSantanderImage: file(relativePath: { eq: "sustainability/santander.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    csMerckImage: file(relativePath: { eq: "sustainability/merck.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "social-story/about.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    posts: allWpPost(filter: { id: { in: ["cG9zdDo0OTM2", "cG9zdDo0OTcx", "cG9zdDo0MzAy"] } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          article {
            theme
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
